
import Tooltip from '../../atoms/Tooltip';
import PageSearch from '../../molecules/PageSearch';
import { useNavigate, useParams } from 'react-router-dom';
import ASearch from '../../organisms/ASearch';
import PageList from '../../templates/PageList';

import NewTooltip from '../../atoms/NewTooltip';

type LoanOrderAnalysis = {
    id: string;
    referralId: number;
    referralUUId: string;
    createdAt: Date;
    formattedCreatedAt: string;
    socialId: string;
    farmerName: string;
    partnerOriginName: string;
    partnerGuarantorName: string;
    username: string;
    approvedAt: Date;
    formattedApprovedAt: string;
};

const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/loan-order-analysis`;

const LoanOrderAnalysisListPage = () => {

    const navigate = useNavigate();

    const { page, pageSize, field: fieldSorted,
        order: orderSorted, filter }: any = useParams();

    let txtId = '';
    let txtSocialId = '';

    if (filter)
        [txtId = '', txtSocialId = ''] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));

    const url = `${BASE_REFERRAL_URL}?socialId=${txtSocialId}`
        + `&page=${page}&pageSize=${pageSize}&id=${txtId}`;

    const renderRows = (rows: LoanOrderAnalysis[]) => {
        return rows.map((row: LoanOrderAnalysis, index: number) => (
            <tr key={row.referralId}
                className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300`}>
                <td className="py-2 px-2">{row.referralId}</td>
                <td className="py-2 px-2">{row.formattedCreatedAt}</td>
                <td className="py-2 px-2 whitespace-nowrap">{row.socialId}</td>
                <td className="py-2 px-2 ">
                    <Tooltip content={row.farmerName}>
                        <div className="truncate">{row.farmerName}</div>
                    </Tooltip>
                </td>

                <td className="py-2 px-2 max-w-0">
                    <Tooltip content={row.partnerOriginName}>
                        <div className="truncate">{row.partnerOriginName}</div>
                    </Tooltip>
                </td>

                <td className="py-2 px-2 max-w-0">
                    <Tooltip content={row.partnerGuarantorName}>
                        <div className="truncate">{row.partnerGuarantorName}</div>
                    </Tooltip>
                </td>

                <td className="py-2 px-2">
                    {row.username}
                </td>

                <td className="py-2 px-2">
                    {row.formattedApprovedAt}
                </td>

                <td>

                </td>
            </tr>
        ));
    }

    const pageChanged = (page: number) => {
        page = page - 1;
        navigate(urlNavigate(page, pageSize, fieldSorted, orderSorted, txtId, txtSocialId), { replace: true });
    };

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtId, txtSocialId), { replace: true });
    }

    const pageSorted = (field: keyof LoanOrderAnalysis | string, order: 'ASC' | 'DESC') => {
        navigate(urlNavigate(page, pageSize, field, order, txtId, txtSocialId), { replace: true });
    }

    const socialIdSearch = (search: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtId, search), { replace: true });
    }

    const idSearch = (id: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, id, txtSocialId), { replace: true });
    }

    const onFilterClean = () => {
        txtId = '';
        txtSocialId = '';
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, '', ''), { replace: true });
    }

    const urlNavigate = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        id: string, socialId: string) => {
        return `/loan-order-analysis/${page}/${pageSize}/${fieldSorted}/${orderSorted}/i=${id};s=${socialId};`
    }

    return (

        <PageList
            pageTitle='Lista de Operações Aprovadas'
            url={url}
            breadcrumb={[{ label: 'Operações aprovadas', url: "#" }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            itemsLabel='operações'
            defaultBackendHeader={false}
            tableHeaders={[
                { id: "col-loan-order-analysis-id", title: "#", width: "70px", canSort: true, field: "referralId" },
                { id: "col-referral-date", title: "Data do Pedido", width: "90px", canSort: false },
                { id: "col-social-id", title: "CPF", width: "120px", canSort: false },
                { id: "col-farmer-name", title: "Nome", width: "180px", canSort: false },
                {
                    id: "col-partner-origin-name",
                    title: "Origem",
                    canSort: false
                },
                {
                    id: "col-partner-guarantor-name",
                    title: "Avalista",
                    canSort: false
                },
                { id: "col-username", title: "Aprovado por", width: "100px", canSort: false },
                { id: "col-approved-at", title: "Data da aprovação", width: "90px", canSort: false },
                { id: "col-actions", title: "", width: "50px", canSort: false }
            ]}
        >
            {{
                filterSlot: (
                    <div className="grid grid-cols-2 gap-1">
                        <ASearch
                            id="txt-id"
                            value={txtId}
                            label="Procurar por operação..."
                            onSearch={idSearch}
                        />
                        <PageSearch
                            value={txtSocialId}
                            label='Procurar por CPF...'
                            onSearch={socialIdSearch}
                            filterCleanVisible={true}
                            filterCleanEnabled={(txtId || txtSocialId) ? true : false}
                            configureVisible={false}
                            onFilterClean={onFilterClean}
                        />
                    </div>),

                controlSlot: (null)
            }}
        </PageList>
    );
};

export default LoanOrderAnalysisListPage;