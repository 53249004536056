import React, { useState, useEffect } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import InputFrame from "../atoms/InputFrame";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

interface DayPickerProps {
    label: string;
    errorMessage?: string;
    value?: string;
    onChange: (date: string) => void;
    textColor?: string;
    displayFormat?: string;
}

const DayPicker: React.FC<DayPickerProps> = ({ label, errorMessage, value, onChange, textColor = "text-gray-900" }) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [inputValue, setInputValue] = useState(value || '');
    const [currentDate, setCurrentDate] = useState(new Date());
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    useEffect(() => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    }, []);

    useEffect(() => {
        if (value) {
            const parsedDate = new Date(value.split('/').reverse().join('-'));
            if (!isNaN(parsedDate.getTime()) && parsedDate.toISOString() !== currentDate.toISOString()) {
                setCurrentDate(parsedDate);
                setInputValue(value);
            }
        } else {
            const today = getCurrentDate();
            if (inputValue !== today) {
                setCurrentDate(new Date());
                setInputValue(today);
                onChange(today);
            }
        }
    }, [value]);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/;
        const newValue = e.target.value;

        setInputValue(newValue);
        if (dateRegex.test(newValue)) {
            onChange(newValue);
        }
    };

    const handleCalendarToggle = () => {
        setShowCalendar(!showCalendar);
    };

    const selectDate = (day: number) => {
        const date = `${String(day).padStart(2, '0')}/${String(currentMonth + 1).padStart(2, '0')}/${currentYear}`;
        setInputValue(date);
        onChange(date);
        setShowCalendar(false);
    };

    const getCurrentDate = (): string => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleMonthChange = (direction: 'prev' | 'next') => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate);
            newDate.setMonth(prevDate.getMonth() + (direction === 'prev' ? -1 : 1));
            return newDate;
        });
    };

    const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedYear = parseInt(e.target.value, 10);
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate);
            newDate.setFullYear(selectedYear);
            return newDate;
        });
    };


    const getDaysInMonth = (year: number, month: number) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const renderDays = () => {
        const daysInMonth = getDaysInMonth(currentYear, currentDate.getMonth());
        const firstDayOfWeek = new Date(currentYear, currentDate.getMonth(), 1).getDay();

        const placeholders = Array.from({ length: firstDayOfWeek }, (_, i) => (
            <div key={`placeholder-${i}`} className="p-2 text-sm text-gray-300">
                {/* Empty placeholder */}
            </div>
        ));

        const days = Array.from({ length: daysInMonth }, (_, i) => (
            <button
                key={`day-${i + 1}`}
                className="p-2 hover:bg-blue-200 text-sm"
                onClick={() => selectDate(i + 1)}
            >
                {i + 1}
            </button>
        ));

        return [...placeholders, ...days];
    };


    const renderYearOptions = () => {
        const yearRange = Array.from({ length: 21 }, (_, i) => currentYear - 10 + i);

        return yearRange.map((year) => (
            <option key={year} value={year}>
                {year}
            </option>
        ));
    };

    return (
        <div className="relative">
            <InputFrame
                label={label}
                errorMessage={errorMessage}
                className="mb-4"
                textColor={textColor}
                prefix={
                    <FaRegCalendarAlt
                        className="text-blue-500 cursor-pointer text-lgz"
                        onClick={handleCalendarToggle}
                    />
                }
            >
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    className={`block py-2.5 px-0 w-full text-sm ${textColor} bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 peer`}
                    placeholder="DD/MM/YYYY"
                />
            </InputFrame>

            {showCalendar && (
                <div className="absolute bg-white border rounded shadow-lg mt-1 p-4 z-50 w-64">
                    <div className="flex items-center justify-between mb-2">
                        <button
                            onClick={() => handleMonthChange('prev')}
                            className="p-2 border border-white hover:border-blue-500 hover:border rounded cursor-pointer"
                        >
                            <IoIosArrowBack />
                        </button>
                        <div className="flex items-center gap-2 max-w-40 min-w-40">
                            <span className="text-sm font-medium">{currentDate.toLocaleString('pt-BR', { month: 'long' }).toLocaleUpperCase()}</span>
                            <select
                                value={currentYear}
                                onChange={handleYearChange}
                                className="text-sm border rounded appearance-none pr-4 w-16"
                            >
                                {renderYearOptions()}
                            </select>
                        </div>
                        <button
                            onClick={() => handleMonthChange('next')}
                            className="p-2 border border-white hover:border-blue-500 hover:border rounded cursor-pointer"
                        >
                            <IoIosArrowForward />
                        </button>
                    </div>

                    <div className="grid grid-cols-7 text-center gap-1">
                        {renderDays()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DayPicker;
