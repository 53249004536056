import { useCallback, useEffect, useRef, useState } from 'react';
import { Auth } from "aws-amplify";

export type FetchHeader = {
    key: string;
    value: string;
};

const useFetch = (url: string, headers?: FetchHeader[]) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isTokenError, setIsTokenError] = useState(false);
    const [data, setData] = useState<any | null>(null);
    const [error, setError] = useState<string | null>(null);
    const token = useRef('');

    const fetchData = useCallback(async (token: string, headers?: FetchHeader[]) => {
        try {
            headers = headers || [];

            headers.push({ key: 'Authorization', value: `Bearer ${token}` });
            headers.push({ key: 'Content-Type', value: 'application/json' });

            const response = await fetch(url, {
                headers: {
                    ...Object.fromEntries(headers.map((header) => [header.key, header.value]))
                },
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setData(data);
            setIsLoading(false);
        } catch (error: unknown) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError(String(error));
            }
            setIsLoading(false);
        }
    }, [url]);

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        Auth.currentSession()
            .then((user) => user.getIdToken().getJwtToken())
            .then((tkn) => {
                if (isMounted) {
                    token.current = tkn;
                    fetchData(tkn, headers);
                }
            })
            .catch((error) => {
                if (isMounted) {
                    setIsTokenError(true);
                    setError(error);
                    setIsLoading(false);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [url, fetchData]);

    return { isLoading, data, error, isTokenError };
};

export default useFetch;