import { FaCheck, FaInfoCircle, FaTimes } from 'react-icons/fa';
import Tooltip from '../../atoms/Tooltip';
import SelectFilter from '../../organisms/SelectFilter';
import PageSearch from '../../molecules/PageSearch';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ASearch from '../../organisms/ASearch';
import ReferralOriginIcon from '../../ions/ReferralOriginIcon';
import AnalysisRecommendationIcon from '../../ions/AnalysisRecommendationIcon';
import PageList from '../../templates/PageList';
import LCBalanceCalcValue from '../../molecules/LCBalanceCalcValue';
import ReferralAdditionalContext from '../../ions/ReferralAdditionalContext';
import ReferralCreditLimitDisplay
    from '../../ions/ReferralCreditLimitDisplay';
import MenuButton from '../../atoms/MenuButton';
import NewTooltip from '../../atoms/NewTooltip';


type OptionKey = "btn-approve" | "btn-reject" | "btn-manual-approve" | "btn-manual-reject";


type Referral = {
    id: number,
    referralUUId: string,
    referralId: number,
    referralDate: string,
    socialId: string,
    name: string, //fullname
    recurrence: boolean,
    partnerOrigin: string,
    partnerOriginId: number,
    partner: string,
    partnerId: number,
    source: string,
    sourceId: number,
    originEnvironment: string,
    guaranteePercentage: number,
    scorePartner: number,
    scoreAgrolend: number,
    creditLimit: number,
    creditLimitFormated: string,
    recommendationCode: string,
    recommendation: string,
    status: string,
    partnerLimit: number,
    limitCreditDivideCreditRuralInPercentual: string,
    limitCreditDivideCreditRuralInPercentualStatus: string,
    creditRuralFormatedValue: string,
    desiredValue: string,
    observation: string,

    canApprove: boolean,
    canReject: boolean,
    canManualApprove: boolean,
    canManualReject: boolean

}

const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/referrals`;
const BASE_SETTLEMENT_URL = `${process.env.REACT_APP_SETTLEMENT_API_URL}/settlement`;

const PartnerAnalysisListPage = () => {

    const navigate = useNavigate();

    const optionsStatus = [{
        value: 'approved',
        label: 'Aprovado'
    }, {
        value: 'not-approved',
        label: 'Reprovado'
    }, {
        value: 'pending',
        label: 'Pendente'
    }, {
        value: 'processing',
        label: 'Em Processamento'
    }, {
        value: 'error',
        label: 'Erro de Processamento'
    }]

    const { page, pageSize, field: fieldSorted,
        order: orderSorted, filter }: any = useParams();

    let txtPartnerName = '';
    let txtRecomendation = '';
    let txtSocialId = '';

    if (filter)
        [txtPartnerName = '', txtRecomendation = '', txtSocialId = ''] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));

    const url = `${BASE_REFERRAL_URL}/partner-analysis-list?search=${txtSocialId}`
        + `&page=${page}&pageSize=${pageSize}&recomendation=${txtRecomendation}&partner=${txtPartnerName}`;


    const navigateForm = (option: string, referralId: string) => {

        const mapper: Record<string, string> = {
            "btn-approve": "form-analysis-approve",
            "btn-reject": "form-analysis-reject",
            "btn-manual-approve": "form-analysis-manual-approve",
            "btn-manual-reject": "form-analysis-manual-reject"
        }

        const analysisType = mapper[option];

        navigate(`/referrals/partner-analysis/${analysisType}/${referralId}`)
    }

    const renderRows = (rows: Referral[]) => {
        return rows.map((row: Referral, index: number) => (
            <tr key={row.referralId}
                className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300`}>
                <td className="py-2 px-2">{row.referralId}</td>
                <td className="py-2 px-2">{row.referralDate}</td>
                <td className="py-2 px-2 whitespace-nowrap">{row.socialId}</td>
                <td className="py-2 px-2 max-w-0">
                    <Tooltip content={row.name} alignment={'right'}>
                        <div className="truncate">{row.name}</div>
                    </Tooltip>
                </td>
                <td className="py-2 px-2" align="center">
                    {row.recurrence
                        ?
                        <NewTooltip content="Sim">
                            <FaCheck style={{ color: 'cornflowerblue' }} />
                        </NewTooltip>
                        :
                        <NewTooltip content="Não">
                            <FaTimes style={{ color: 'rgb(242, 5, 37)' }} />
                        </NewTooltip>
                    }
                </td>

                <td className="py-2 px-2 truncate max-w-0" align="center" style={{ maxWidth: '5ch' }}>
                    <ReferralAdditionalContext
                        text={row.observation}
                    />
                </td>

                <td className="py-2 px-2 max-w-0">
                    <Tooltip content={row.partnerOrigin} alignment={'right'}>
                        <div className="truncate">{row.partnerOrigin}</div>
                    </Tooltip>

                </td>

                <td className="py-2 px-2 max-w-0">
                    <Tooltip content={row.partner} alignment={'right'}>
                        <div className="truncate">{row.partner}</div>
                    </Tooltip>
                </td>

                <td className="py-2 px-2">
                    {row.guaranteePercentage}%
                </td>

                <td className="py-2 px-2 text-right">
                    {row.scorePartner !== null ? row.scorePartner.toFixed(2) : '0.00'}
                </td>

                <td className="py-2 px-2" style={{ verticalAlign: 'middle' }} align="right">

                    <div className="flex items-center text-right justify-end">
                        {row.scoreAgrolend}
                        <Link to={`agrolend-score/${row.referralUUId}`} className="ml-2">
                            <FaInfoCircle style={{ color: 'cornflowerblue' }} />
                        </Link>
                    </div>
                </td>

                <td className="py-2 px-2" align="center">
                    <Link to={`biro-list/${row.referralUUId}/${row.partnerId}`}>
                        <FaInfoCircle style={{ color: 'cornflowerblue' }} />
                    </Link>
                </td>

                <td className="py-2 px-2">
                    <div className="flex  items-center justify-end text-nowrap">
                        <span className="mr-2">{row.desiredValue}</span>
                    </div>
                </td>

                <td className="py-2 px-2">
                    <div className="flex  items-center justify-end">
                        <span className="mr-2">{row.creditLimitFormated}</span>
                        <AnalysisRecommendationIcon
                            code={row.recommendationCode}
                            description={row.recommendation} />
                    </div>
                </td>

                <td className="py-2 px-2">
                    <div className="flex  items-center justify-center text-nowrap">
                        <ReferralCreditLimitDisplay
                            creditLimitFormatted={row.creditLimitFormated}
                            limitCreditDivideCreditRuralInPercent={row.limitCreditDivideCreditRuralInPercentual}
                            recommendation={row.recommendationCode}
                            creditRuralFormattedValue={row.creditRuralFormatedValue}
                            creditRuralStatus={row.limitCreditDivideCreditRuralInPercentualStatus} />
                    </div>
                </td>

                <td className="py-2 px-2">
                    <div className="flex items-center justify-end">
                        <span className="mr-2">
                            <LCBalanceCalcValue
                                url={`${BASE_SETTLEMENT_URL}/farmer/debt/${row.socialId}`}
                                lessValue={row.creditLimit}
                                jsonField="value"
                                formatBRL={true}
                                toolTip="Operações em aberto: $$"
                            />
                        </span>
                    </div>
                </td>

                <td className="py-2 px-2" align="center">
                    <Link to={`referral-detail/${row.referralUUId}`}>
                        <FaInfoCircle style={{ color: 'cornflowerblue' }} />
                    </Link>
                </td>

                <td>
                    {(row.canApprove || row.canReject || row.canManualApprove || row.canManualReject) && (
                        <MenuButton
                            options={[
                                { key: "btn-approve", label: 'Aprovar', backgroundColor: '#2563eb', type: "item", visible: row.canApprove },
                                { key: "btn-reject", label: 'Reprovar', backgroundColor: '#b91c1c', type: "item", visible: row.canReject },
                                { key: "separator", type: "separator", visible: row.canManualApprove || row.canManualReject },
                                { key: "btn-manual-approve", label: 'Aprovar manualmente', backgroundColor: '#059669', type: "item", visible: row.canManualApprove },
                                { key: "btn-manual-reject", label: 'Reprovar manualmente', backgroundColor: '#9f1239', type: "item", visible: row.canManualReject },
                            ]}
                            onSelect={(option: string) => navigateForm(option, row.referralUUId)}
                        />
                    )}
                </td>
            </tr>
        ));
    }

    const pageChanged = (page: number) => {
        page = page - 1;
        navigate(urlNavigate(page, pageSize, fieldSorted, orderSorted, txtSocialId, txtRecomendation, txtPartnerName), { replace: true });
    };

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtSocialId, txtRecomendation, txtPartnerName), { replace: true });
    }

    const pageSorted = (field: keyof Referral | string, order: 'ASC' | 'DESC') => {
        navigate(urlNavigate(page, pageSize, field, order, txtSocialId, txtRecomendation, txtPartnerName), { replace: true });
    }

    const socialIdSearch = (search: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, search, txtRecomendation, txtPartnerName), { replace: true });
    }

    const partnerSearch = (partnerName: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtSocialId, txtRecomendation, partnerName), { replace: true });
    }

    const filterRecommendation = (recomendation: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtSocialId, recomendation, txtPartnerName), { replace: true });
    }

    const onFilterClean = () => {
        txtPartnerName = '';
        txtRecomendation = '';
        txtSocialId = '';
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, '', '', ''), { replace: true });
    }

    const urlNavigate = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        socialId: string, recomendation: string, partner: string) => {
        return `/referrals/partner-analysis/${page}/${pageSize}/${fieldSorted}/${orderSorted}/p=${partner};r=${recomendation};s=${socialId};`
    }



    return (

        <PageList
            pageTitle='Lista de Pedidos - Revenda'
            url={url}
            breadcrumb={[{ label: 'Pedidos da revenda', url: "#" }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            itemsLabel='pedidos'
            defaultBackendHeader={false}
            tableHeaders={[
                { id: "col-referral-id", title: "#", width: "70px", canSort: true, field: "referralId" },
                { id: "col-referral-date", title: "Data do Pedido", width: "90px", canSort: false },
                { id: "col-social-id", title: "CPF", width: "120px", canSort: false },
                { id: "col-name", title: "Nome", canSort: false },
                {
                    id: "col-recurrence",
                    title: "Rec.",
                    width: "40px",
                    canSort: false,
                    headerTooltipDescription: "Recorrente - Possui CPR assinada no passado, independente do status"
                },
                {
                    id: "col-type",
                    title: "Tipo",
                    width: "40px",
                    canSort: false,
                    headerTooltipDescription: "Tipo de recorrência"
                },
                { id: "col-partner", title: "Origem", canSort: false },
                { id: "col-partner-avalista", title: "Avalista", canSort: false },
                { id: "col-guarantee-percentage", title: "Aval", width: "50px", canSort: false },
                {
                    id: "col-score-partner",
                    title: "Score A.",
                    width: "80px",
                    canSort: false,
                    headerTooltipDescription: "Avalista"
                },
                {
                    id: "col-score-agrolend",
                    title: "Score AGL",
                    width: "90px",
                    canSort: false,
                    headerTooltipDescription: "Score Agrolend - CPF"
                },
                { id: "col-provider-result", title: "Birôs", width: "40px", canSort: false },
                {
                    id: "col-value-desired",
                    title: "$ pedido",
                    width: "150px",
                    canSort: false,
                    headerTooltipDescription: "$ pretendido pelo CPF"
                },
                {
                    id: "col-credit-limit",
                    title: "L. C.",
                    width: "150px",
                    canSort: false,
                    headerTooltipDescription: "Limite de crédito calculado pelo sistema"
                },
                {
                    id: "col-credit-limit-divide-credit-rural",
                    title: "L.C./C.R. M0",
                    width: "90px",
                    canSort: false,
                    headerTooltipDescription: "LC calculado pelo sistema / crédito rural do mês mais recente consultado pelo motor de crédito"
                },
                {
                    id: "col-available-limit",
                    title: "L.C. disponível",
                    width: "150px",
                    canSort: false,
                    headerTooltipDescription: "Limite de crédito calculado - operações em aberto"
                },
                { id: "col-details", title: "Detalhes", width: "75px", canSort: false },
                { id: "col-actions", title: "", width: "50px", canSort: false }
            ]}
        >
            {{
                filterSlot: (
                    <div className="grid grid-cols-3 gap-1">
                        <ASearch
                            id="src-partner-name"
                            value={txtPartnerName}
                            label="Procurar por origem..."
                            onSearch={partnerSearch}
                        />

                        <SelectFilter
                            id="slc-status"
                            label="Recomendação ..."
                            value={txtRecomendation}
                            onChange={filterRecommendation}
                            defaultVoidOption={true}
                            options={optionsStatus}
                        />

                        <PageSearch
                            value={txtSocialId}
                            label='Procurar por CPF...'
                            onSearch={socialIdSearch}
                            filterCleanVisible={true}
                            filterCleanEnabled={(txtPartnerName || txtRecomendation || txtSocialId) ? true : false}
                            configureVisible={false}
                            onFilterClean={onFilterClean}
                        />
                    </div>),

                controlSlot: (null)
            }}
        </PageList>
    );
};

export default PartnerAnalysisListPage;