import { FaFileUpload } from 'react-icons/fa';
import Tooltip from '../../atoms/Tooltip';
import SelectFilter from '../../organisms/SelectFilter';
import PageSearch from '../../molecules/PageSearch';
import { useNavigate, useParams } from 'react-router-dom';
import ASearch from '../../organisms/ASearch';

import PageList from '../../templates/PageList';

import { BATCH_ANALYSIS_LIST } from '../../organisms/TwoSidebar';

type BatchAnalysis = {
    id: string;
    formattedCreatedAt: string;
    fileName: string;
    fileType: string;
    fileSize: number;
    username: string;
    partnerOriginLegalName: String;
    partnerOriginFantasyName: String;
    partnerLegalName: string;
    partnerFantasyName: string;
    status: string;
    formattedStatus: string;
    totalProcessing: number;
    totalInvalids: number;
    totalRows: number;
    totalErrors: number;
    totalSuccess: number;
    processCount: number;
}

const batchAnalysisStatus = [
    { "value": "CREATED", "label": "Criado" },
    { "value": "PROCESSING", "label": "Em processamento" },
    { "value": "PROCESSED", "label": "Processado" },
    { "value": "ERROR", "label": "Erro no processamento" }
];

const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/analysis`;

const BatchAnalysisListPage = () => {

    const navigate = useNavigate();

    const { page, pageSize, field: fieldSorted,
        order: orderSorted, filter }: any = useParams();

    let txtPartnerName = '';
    let txtStatus = '';
    let txtFilename = '';

    if (filter)
        [txtFilename = '', txtStatus = '', txtPartnerName = ''] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));

    const url = BASE_REFERRAL_URL + `/batch-analysis-list?fileName=${txtFilename}`
        + `&page=${page}&pageSize=${pageSize}&partnerName=${txtPartnerName}`
        + `&status=${txtStatus}`;

    const getCssColorBy = (status: string | undefined) => {
        if (!status) return 'bg-gray-800';

        const cssClazz: Record<string, string> = {
            'PROCESSING': 'bg-blue-500',
            'CREATED': 'bg-gray-800',
            'ERROR': 'bg-red-500',
            'PROCESSED': 'bg-green-500'
        }
        return cssClazz[status.toUpperCase()];
    };


    const renderRows = (rows: BatchAnalysis[]) => {

        if (!rows || rows.length === 0) {
            return (
                <tr>
                    <td colSpan={9} className="py-2 px-2 text-center">
                        <div className="text-2xl font-bold text-gray-500 mt-4">
                            Nenhum registro encontrado...
                        </div>
                    </td>
                </tr>
            );
        }

        return rows.map((row: BatchAnalysis, index: number) => (
            <tr key={row.id}
                onClick={() => navigate(`/analysis/batch-analysis/view/${row.id}`)}
                className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300 cursor-pointer`}>
                <td className="py-2 px-2">{row.formattedCreatedAt}</td>
                <td className="py-2 px-2 truncate max-w-0">
                    <Tooltip content={`${row.partnerOriginFantasyName}`}>
                        {row.partnerOriginLegalName}
                    </Tooltip>
                </td>
                <td className="py-2 px-2 truncate max-w-0">
                    <Tooltip content={`${row.partnerFantasyName}`}>
                        {row.partnerLegalName}
                    </Tooltip>
                </td>
                <td className="py-2 px-2 truncate max-w-0">
                    <Tooltip content={`${row.fileName}`} alignment="bottom">
                        {row.fileName}
                    </Tooltip>
                </td>

                <td className="py-2 px-2 truncate max-w-0">
                    {row.username}
                </td>

                <td className="py-2 px-2" align="center">
                    {row.totalSuccess}
                </td>

                <td className="py-2 px-2">
                    {row.totalInvalids}
                </td>

                <td className="py-2 px-2">
                    {row.totalErrors}
                </td>

                <td className="py-2 px-2">
                    {row.totalRows}
                </td>

                <td className="py-2 px-2" align="center">
                    <Tooltip content={`Tentativas de reprocesso: ${row.processCount}`} alignment="left">
                        <span className={`rounded px-1 py-1 text-xs font-bold text-white ${getCssColorBy(row.status)}`}>
                            {row.formattedStatus}
                        </span>
                    </Tooltip>
                </td>
            </tr>
        ));
    }

    const pageChanged = (page: number) => {
        page = page - 1;
        navigate(urlNavigate(page, pageSize, fieldSorted, orderSorted, txtFilename, txtStatus, txtPartnerName), { replace: true });
    }

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtFilename, txtStatus, txtPartnerName), { replace: true });
    }

    const pageSorted = (field: keyof BatchAnalysis | string, order: 'ASC' | 'DESC') => {
        navigate(urlNavigate(page, pageSize, field, order, txtFilename, txtStatus, txtPartnerName), { replace: true });
    }

    const txtFileNameChange = (fileName: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, fileName, txtStatus, txtPartnerName), { replace: true });
    }

    const txtPartnerNameClick = (partnerName: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtFilename, txtStatus, partnerName), { replace: true });
    }

    const filterStatusChange = (status: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtFilename, status, txtPartnerName), { replace: true });
    }

    const onFilterClean = () => {
        txtPartnerName = '';
        txtStatus = '';
        txtFilename = '';
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, '', '', ''), { replace: true });
    }

    const urlNavigate = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        fileName: string, status: string, partnerName: string) => {
        return `${BATCH_ANALYSIS_LIST}/${page}/${pageSize}/${fieldSorted}/${orderSorted}/fn=${fileName};a=${status};p=${partnerName};`
    }

    const newBatchAnalysisAction = () => {
        navigate("/analysis/batch-analysis/form");
    }

    return (

        <PageList
            pageTitle='Análises em lote'
            url={url}
            breadcrumb={[{ label: 'Análises em lote', url: "#" }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            itemsLabel='análises'
        >
            {{
                filterSlot: (
                    <div className="grid grid-cols-12 gap-1">
                        <ASearch
                            id="src-partner-name"
                            value={txtPartnerName}
                            label="Procurar por origem..."
                            onSearch={txtPartnerNameClick}
                            className="col-span-4"
                        />

                        <SelectFilter
                            id="slc-referral-state"
                            label="Estado do processamento ..."
                            value={txtStatus}
                            onChange={filterStatusChange}
                            defaultVoidOption={true}
                            defaultLabel='Todos...'
                            options={batchAnalysisStatus}
                            className='col-span-4'
                        />

                        <PageSearch
                            value={txtFilename}
                            label='Procurar pelo nome do arquivo'
                            onSearch={txtFileNameChange}
                            filterCleanVisible={true}
                            filterCleanEnabled={(txtPartnerName || txtStatus || txtFilename) ? true : false}
                            configureVisible={false}
                            onFilterClean={onFilterClean}
                            className='col-span-4'
                        />
                    </div>),

                controlSlot: (
                    <div className='pt-4' >
                        <button type='button'
                            className='text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2'
                            onClick={newBatchAnalysisAction}>
                            <div className="flex items-center">
                                <FaFileUpload className='mr-2' />
                                Subir arquivo
                            </div>
                        </button>
                    </div>
                )
            }}
        </PageList >
    );
};

export default BatchAnalysisListPage;