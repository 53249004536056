export const currentDateAsString = (): string => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0'); // Ensure two digits
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = today.getFullYear();

    return `${day}/${month}/${year}`;
};

export const extractAndConvertFrom = (dateTime: string) => {
    if (!dateTime) {
        return '';
    }
    return dateTime.split(' ')[0].split('-').reverse().join('/');
};