import PageSearch from '../../molecules/PageSearch';
import { useNavigate, useParams } from 'react-router-dom';
import PageList from '../../templates/PageList';
import { FaCheck } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { FaWhatsapp } from "react-icons/fa6";
import { MdMailOutline } from "react-icons/md";
import { extractAndConvertFrom } from '../../../utils/DateUtils';
import SelectFilter from '../../organisms/SelectFilter';
import DayPicker from '../../molecules/DayPicker';
import NewTooltip from '../../atoms/NewTooltip';

type Contact = {
    fullName: string;
    isValidWhatsApp: boolean;
    phoneNumber: string;
    isValidEmail: boolean;
    email: string;
}

type Notification = {
    type_scheduled: string;
    operation: string;
    updatedAt: string;
    maxAttempts: number;
    attempts: number;
    media: string;
    status: string;
    createdAt: string;
    sentAt: string;

    contact: Contact;
}

const DEFAULT_SCHEDULED_TYPE = 'WPP_DUPLICATE_BILLING_DUE_D-30';
const API_KEY = process.env.REACT_APP_COMMUNICATION_API_KEY || '';

export const DEFAULT_SCHEDULED_TYPES = [
    { value: 'WPP_DUPLICATE_BILLING_DUE_D-30', label: 'Cobrança duplicata 30 dias (whastapp)' },
    { value: 'MAIL_DUPLICATE_BILLING_DUE_D-30', label: 'Cobrança duplicata 30 dias (email)' },
];


const NotificationPageList = () => {
    const navigate = useNavigate();

    const { page, pageSize, field: fieldSorted, order: orderSorted, filter }: any = useParams();

    let txtFullName = '';
    let txtScheduledType = '';
    let txtTargetDate = '';

    const TODAY = new Date().toISOString().split('T')[0];

    if (filter) {
        [txtFullName = '', txtScheduledType, txtTargetDate] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));
        console.log(txtFullName, txtScheduledType, txtTargetDate);

        if (!txtScheduledType)
            txtScheduledType = DEFAULT_SCHEDULED_TYPE;

        if (!txtTargetDate)
            txtTargetDate = TODAY;
    }

    const url = `${process.env.REACT_APP_COMMUNICATION_API_URL}/notifications?fullName=${txtFullName}&notification_type=${txtScheduledType}&target_date=${txtTargetDate}&page=${page}&pageSize=${pageSize}&order=${orderSorted}`;

    const getCssColorBy = (status: string) => {

        const statusTable = {
            "PENDING": "bg-slate-400",
            "CHECKING": "bg-slate-500",
            "SENT": "bg-green-500",
            "ERROR": "bg-red-500",
            "RECEIVED": "bg-blue-500",
            "DELIVERED": "bg-blue-500"
        }

        return statusTable[status as keyof typeof statusTable] || "bg-gray-500";
    };

    const renderRows = (rows: Notification[]) => {
        if (!rows || rows.length === 0) {
            return (
                <tr>
                    <td colSpan={5} className="py-2 px-2 text-center">
                        <div className="text-2xl font-bold text-gray-500 mt-4">
                            Nenhuma notificação para esse dia...
                        </div>
                    </td>
                </tr>
            );
        }

        return rows.map((notification: Notification, index: number) => (
            <tr
                key={notification.operation}
                className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border-gray-300`}
            >
                <td className="py-2 px-2">
                    {notification.operation}
                </td>
                <td className="py-2 px-2">
                    {notification.contact.fullName}
                </td>
                <td className="py-2 px-2">
                    {notification.attempts} de {notification.maxAttempts}
                </td>
                <td>
                    {notification.media === "WHATSAPP" ? (
                        <span className='flex items-center'>
                            <NewTooltip content="Tipo de mídia">
                                <FaWhatsapp className='mr-3' />
                            </NewTooltip>
                            <NewTooltip content="Número do telefone">
                                {notification.contact.phoneNumber}
                            </NewTooltip>
                            {notification.contact.isValidWhatsApp ? (
                                <NewTooltip content="WhatsApp válido">
                                    <FaCheck className='ml-2 text-green-700' />
                                </NewTooltip>
                            ) : (
                                <NewTooltip content="WhatsApp inválido">
                                    <FcCancel className='ml-2' />
                                </NewTooltip>
                            )}
                        </span>
                    ) : (
                        <span className='flex items-center'>
                            <NewTooltip content="Tipo de mídia">
                                <MdMailOutline className='mr-3' />
                            </NewTooltip>
                            {notification.contact.email}
                            {notification.contact.isValidEmail ? (
                                <NewTooltip content="Email válido">
                                    <FaCheck className='ml-2 text-green-700' />
                                </NewTooltip>
                            ) : (
                                <NewTooltip content="Email inválido">
                                    <FcCancel className='ml-2' />
                                </NewTooltip>
                            )}
                        </span>
                    )}
                </td>
                <td>
                    {extractAndConvertFrom(notification.updatedAt)}
                </td>
                <td className="py-2 px-2" align="center">
                    <span className={`rounded px-1 py-1 text-xs font-bold text-white ${getCssColorBy(notification.status)}`}>
                        {notification.status}
                    </span>
                </td>
            </tr>
        ));
    };

    const pageChanged = (page: number) => {
        navigate(urlNavigate(page - 1, pageSize, fieldSorted, orderSorted, txtFullName, txtScheduledType, txtTargetDate), { replace: true });
    };

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtFullName, txtScheduledType, txtTargetDate), { replace: true });
    };

    const pageSorted = (field: keyof Notification | string, order: 'ASC' | 'DESC') => {
        navigate(urlNavigate(page, pageSize, field, order, txtFullName, txtScheduledType, txtTargetDate), { replace: true });
    };

    const txtContactNameChanged = (description: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, description, txtScheduledType, txtTargetDate), { replace: true });
    };

    const onFilterClean = () => {
        const today = new Date().toISOString().split('T')[0];
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, '', DEFAULT_SCHEDULED_TYPE, today), { replace: true });
    };

    const urlNavigate = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        fullName: string, scheduledType: string, targetDate: string) => {
        return `/communication/notification/${page}/${pageSize}/${fieldSorted}/${orderSorted}/f=${fullName};s=${scheduledType};d=${targetDate};`;
    };



    const filterScheduledTypeChange = (value: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtFullName, value, txtTargetDate), { replace: true });
    };

    const filterTargetDateChange = (value: string) => {
        value = value.split('/').reverse().join('-');
        console.log(value);
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtFullName, txtScheduledType, value), { replace: true });
    };

    return (
        <PageList
            pageTitle={"Notificações do dia"}
            url={url}
            fetchHeaders={[
                { key: 'x-api-key', value: API_KEY },
            ]}
            breadcrumb={[{ label: 'notificações whatsapp', url: "#" }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            itemsLabel="notificações"
            defaultBackendHeader={false}
            tableHeaders={[{
                id: "column1",
                title: "Operação",
                width: "155px",
                class: 'text-center',
                field: 'operation',
                canSort: true,
            }, {
                id: "column2",
                title: 'Nome do contato',
                field: "contact.fullName",
                canSort: true,
            }, {
                id: "column3",
                title: 'Tentativas',
                width: "100px",
                field: "attempts",
                canSort: false,
            }, {
                id: "column4",
                title: 'Contato',
                field: "phoneNumber",
                width: "300px",
                canSort: false,
            },
            {
                id: "column5",
                title: 'Atualizado em',
                field: "updatedAt",
                width: "130px",
                canSort: false,
            }, {
                id: "column6",
                title: 'Status',
                field: "status",
                width: "100px",
                canSort: false,
            }]}>
            {{
                filterSlot: (
                    <div className="grid grid-cols-3 gap-1">
                        <SelectFilter
                            id="slc-scheduled-type"
                            label="Tipo de notificação ..."
                            value={txtScheduledType}
                            onChange={filterScheduledTypeChange}
                            defaultVoidOption={true}
                            options={DEFAULT_SCHEDULED_TYPES}
                        />

                        <DayPicker label="Agendado para..."
                            value={txtTargetDate.split('-').reverse().join('/')}
                            onChange={filterTargetDateChange}
                        />

                        <PageSearch
                            value={txtFullName}
                            label="Procurar por..."
                            onSearch={txtContactNameChanged}
                            filterCleanVisible={true}
                            filterCleanEnabled={Boolean(txtFullName)}
                            onFilterClean={onFilterClean}
                            configureVisible={false}
                        />
                    </div>
                ),
                controlSlot: (
                    <div className='pt-4' >
                        {/* <Link to={`form?analysisType=${analysisType}`}
                            className='text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2'>
                            Nova notificação
                        </Link> */}
                    </div>
                )
            }}
        </PageList>
    );
};

export default NotificationPageList;